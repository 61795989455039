import { reactive, toRefs, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Icon } from "@/components";
import $ from "jquery";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "LayoutFooter",
  components: { Icon },
  setup() {
    const state = reactive({
      navigationActive: "ex",
    });

    const router = useRouter();
    const route = useRoute();

    const showNavigation = computed(() => {
      if (route.name == "Home") return false;
      return true;
    });

    const handleNavigation = (acitve) => {
      state.navigationActive = acitve;
      // change content here
      router.push({
        name:
          acitve == "ex"
            ? "MetaWorldViewer"
            : acitve == "co"
            ? "MetaContents"
            : "AvatarCreationExperience",
      });
    };

    const handleExpandMenu = () => {
      console.log("expand");
      if (!$(".navigation-inner.expand").hasClass("opened")) {
        $(".navigation-inner.expand").addClass("opened");
      }
    };

    const handleCloseMenu = () => {
      console.log("close");
      if ($(".navigation-inner.expand").hasClass("opened")) {
        $(".navigation-inner.expand").removeClass("opened");
      }
    };

    return {
      ...toRefs(state),
      handleNavigation,
      handleExpandMenu,
      handleCloseMenu,
      showNavigation,
      isMobileOnly,
    };
  },
};
