<style src="./MainLayout.scss" lang="scss" scoped></style>
<script src="./MainLayout.js"></script>

<template>
	<section class="main-layout" id="content-main">
		<TheHeader />
		<TheSidebar />
		<RouterView :key="$route.fullPath" />
		<TheFooter />
	</section>
</template>