<style src="./Footer.scss" lang="scss" scoped></style>
<script src="./Footer.js"></script>

<template>
	<!-- mobile nav footer -->
	<aside class="footer-navigation" v-if="showNavigation">
		<ul class="navigation-list">
			<li class="navigation-item">
				<div
					class="navigation-inner"
					:class="{ active: $route.name === 'AvatarCreationExperience' }"
				>
					<img
						:src="
							require(`@/assets/images/navigation_id${
								navigationActive === 'id' ? '_active' : ''
							}.png`)
						"
						class="navigation-img"
						@click.prevent="handleNavigation('id')"
					/>
					<div class="navigation-line"></div>
				</div>
			</li>
			<li class="navigation-item">
				<div
					class="navigation-inner"
					:class="{ active: $route.name === 'MetaContents' }"
				>
					<img
						:src="
							require(`@/assets/images/navigation_co${
								navigationActive === 'co' ? '_active' : ''
							}.png`)
						"
						class="navigation-img"
						@click.prevent="handleNavigation('co')"
					/>
					<div class="navigation-line"></div>
				</div>
			</li>
			<li class="navigation-item">
				<div
					class="navigation-inner"
					:class="{ active: $route.name === 'MetaWorldViewer' }"
				>
					<img
						:src="
							require(`@/assets/images/navigation_ex${
								navigationActive === 'ex' ? '_active' : ''
							}.png`)
						"
						class="navigation-img"
						@click.prevent="handleNavigation('ex')"
					/>
				</div>
			</li>
		</ul>
	</aside>
	<!-- footer -->
	<footer class="footer-contain">
		<div class="content-inner">
			<ul class="footer-list">
				<li class="footer-item">
					<router-link to="#" class="footer-link">©2021 Metalab</router-link>
				</li>
				<li class="footer-item" v-if="isMobileOnly">
					<router-link to="/About" class="footer-link">About Us</router-link>
				</li>
				<li class="footer-item" v-if="isMobileOnly">
					<router-link to="/Contact" class="footer-link">Contact</router-link>
				</li>
				<!-- <li class="footer-item">
					<router-link to="#" class="footer-link">Privacy</router-link>
				</li>
				<li class="footer-item">
					<router-link to="#" class="footer-link">Terms</router-link>
				</li> -->
			</ul>
		</div>
	</footer>
</template>