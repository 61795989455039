<style src="./Header.scss" lang="scss" scoped></style>
<script src="./Header.js"></script>

<template>
	<header class="header-contain">
		<div class="content-inner">
			<!-- mobile nav -->
			<div class="navigation-mobile">
				<!-- nav close -->
				<div class="navigation-inner close">
					<div class="navigation-head">
						<router-link to="/">
							<div class="logo-mb">
								<img src="@/assets/images/logo_mb.png" />
							</div>
						</router-link>
						<router-link to="" @click.prevent="handleExpandMenu">
							<div class="hambergur">
								<img src="@/assets/images/hambergur.png" />
							</div>
						</router-link>
					</div>
				</div>
				<!-- nav expand -->
				<div class="navigation-inner expand">
					<div class="navigation-head">
						<router-link to="/" @click.prevent="handleCloseMenu">
							<div class="logo-mb">
								<img src="@/assets/images/logo_mb.png" />
							</div>
						</router-link>
						<router-link to="" @click.prevent="handleCloseMenu">
							<div class="close">
								<img src="@/assets/images/close.png" />
							</div>
						</router-link>
					</div>
					<div class="navigation-body">
						<ul class="navigation-list">
							<li
								class="navigation-item"
								:class="{ active: $route.name === 'Home' }"
							>
								<router-link to="" @click.prevent="handlePlay">
									<img src="@/assets/images/menu_meta_world.png" />
									<span>Enter the Meta World</span>
								</router-link>
							</li>
							<!-- <li
								class="navigation-item"
								:class="{ active: $route.name === 'CurrentEvent' }"
							>
								<router-link
									to="/current-event"
									@click.prevent="handleCloseMenu"
								>
									<img src="@/assets/images/menu_current_events.png" />
									<span>Current Events</span>
								</router-link>
							</li> -->
							<li
								class="navigation-item"
								:class="{ active: $route.name === 'About' }"
							>
								<router-link to="/About" @click.prevent="handleCloseMenu">
									<img src="@/assets/images/menu_about.png" />
									<span>About</span>
								</router-link>
							</li>
							<li
								class="navigation-item"
								:class="{ active: $route.name === 'Contact' }"
							>
								<router-link to="/Contact" @click.prevent="handleCloseMenu">
									<img src="@/assets/images/menu_contact.png" />
									<span>Contact</span>
								</router-link>
							</li>
						</ul>
					</div>
					<div class="navigation-footer">
						<div class="footer-inner">
							<ul class="footer-list">
								<li class="footer-item">
									<router-link to="#" class="footer-link"
										>©2021 Metalab</router-link
									>
								</li>
								<!--<li class="footer-item">
									<router-link
										to="/About"
										class="footer-link"
										@click.prevent="handleCloseMenu"
										>About Us</router-link
									>
								</li>
								<li class="footer-item">
									<router-link
										to="/Contact"
										class="footer-link"
										@click.prevent="handleCloseMenu"
										>Contact</router-link
									>
								</li>
								<li class="footer-item">
									<router-link to="#" class="footer-link">Privacy</router-link>
								</li>
								<li class="footer-item">
									<router-link to="#" class="footer-link">Terms</router-link>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- desktop nav -->
			<div
				id="navigation"
				class="navigation"
				:class="{ hide: !showNavigation }"
			>
				<ul class="navigation-list">
					<li class="navigation-item">
						<div class="navigation-inner">
							<img
								:src="
									require(`@/assets/images/navigation_id${
										navigationActive === 'id' ? '_active' : ''
									}.png`)
								"
								class="navigation-img"
								@click.prevent="handleNavigation('id')"
							/>
							<div class="navigation-line"></div>
						</div>
					</li>
					<li class="navigation-item">
						<div class="navigation-inner">
							<img
								:src="
									require(`@/assets/images/navigation_co${
										navigationActive === 'co' ? '_active' : ''
									}.png`)
								"
								class="navigation-img"
								@click.prevent="handleNavigation('co')"
							/>
							<div class="navigation-line"></div>
						</div>
					</li>
					<li class="navigation-item">
						<div class="navigation-inner">
							<img
								:src="
									require(`@/assets/images/navigation_ex${
										navigationActive === 'ex' ? '_active' : ''
									}.png`)
								"
								class="navigation-img last"
								@click.prevent="handleNavigation('ex')"
							/>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>