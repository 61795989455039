<style src="./Sidebar.scss" lang="scss" scoped></style>
<script src="./Sidebar.js"></script>

<template>
	<aside class="sidebar-contain">
		<div id="sidebar" class="content-inner">
			<router-link to="/">
				<div class="logo">
					<img src="@/assets/images/logo.png" />
				</div>
			</router-link>
			<div class="sidebar-menu">
				<ul class="sidebar-list">
					<li class="sidebar-item">
						<div
							class="sidebar-line first"
							:class="{ top_pad: showNavigation }"
						></div>
						<div
							class="sidebar-meta--content"
							v-if="!showNavigation"
							:class="{ reverse_nav: $route.name === 'CurrentEvent' }"
						>
							<router-link to="" @click.prevent="handlePlay">
								<!-- class="sidebar-inner first" -->
								<div
									class="sidebar-inner"
									:class="{ active: $route.name === 'Home' }"
								>
									<img
										class="sidebar-img"
										src="@/assets/images/menu_meta_world_active.png"
									/>
									<div class="sidebar-link">Enter the Meta World</div>
								</div>
							</router-link>
							<!-- <router-link to="/current-event">
								<div
									class="sidebar-inner last"
									:class="{ active: $route.name === 'CurrentEvent' }"
								>
									<img
										class="sidebar-img"
										src="@/assets/images/menu_current_events.png"
									/>
									<div class="sidebar-link">Current Events</div>
								</div>
							</router-link> -->
						</div>
						<div class="sidebar-meta--content no-pad" v-else>
							<div
								class="sidebar-page-active"
								v-if="$route.name == 'MetaWorldViewer'"
							>
								Meta Experience
							</div>
							<div
								class="sidebar-page-active"
								v-else-if="$route.name == 'AvatarCreationExperience'"
							>
								Meta Identity
							</div>
							<div
								class="sidebar-page-active"
								v-else-if="$route.name == 'MetaContents'"
							>
								Meta Contents
							</div>
						</div>
						<div
							class="sidebar-line last"
							:class="{ bottom_pad: showNavigation }"
						></div>
					</li>
					<li class="sidebar-item">
						<router-link
							to="/About"
							class="sidebar-link-item"
							:class="{ active: $route.name === 'About' }"
						>
							About
						</router-link>
					</li>
					<li class="sidebar-item">
						<router-link
							to="/Contact"
							class="sidebar-link-item"
							:class="{ active: $route.name === 'Contact' }"
						>
							Contact
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</aside>
</template>