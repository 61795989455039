import { reactive, toRefs, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Icon } from "@/components";
import { gsap } from "gsap";
import $ from "jquery";
// import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

export default {
  name: "LayoutSidebar",
  components: { Icon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      vdoStatus: false,
    });

    const showNavigation = computed(() => {
      if (
        route.name == "Home" ||
        route.name == "About" ||
        route.name == "Contact" ||
        route.name == "CurrentEvent"
      )
        return false;
      return true;
    });

    const handlePlay = () => {
      if (route.name !== "Home") {
        router.push({
          name: "Home",
        });
      } else {
        // change content here
        var media = document.querySelector("#vdo_meta_world");
        media.currentTime = 0;
        // gsap.registerPlugin(ScrollToPlugin);
        // gsap.fromTo(
        //   "#sidebar",
        //   { x: 0, y: 0, autoAlpha: 1, scale: 1 },
        //   { opacity: 1, duration: 0.5, delay: 1.5, ease: "Linear.easeInOut" }
        // );
        gsap.fromTo(
          "#sidebar",
          { x: 0, y: 0, autoAlpha: 1, scale: 1 },
          {
            x: 100,
            y: 20,
            autoAlpha: 0,
            scale: 0.7,
            delay: 0.25,
            duration: 0.8,
            ease: "power4",
            onUpdate: () => {
              media.play();
            },
            onComplete: () => {
              $(".welcome-contain .container").css({
                "padding-top": "56.25%",
                height: "auto",
              });
            },
          }
        );
      }
    };

    return {
      ...toRefs(state),
      handlePlay,
      showNavigation,
    };
  },
};
