import TheHeader from "@/layouts/Header";
import TheSidebar from "@/layouts/Sidebar";
import TheFooter from "@/layouts/Footer";

export default {
  name: "LandingLayout",
  components: {
    TheHeader,
    TheSidebar,
    TheFooter,
  },
};
